<template>
  <div v-if="show">
    <div class="modal__overlay">
      <div class="modal__card modal__card--right contectUs">
        <div class="row">
          <div class="col align-self-end" style="text-align: right">
            <button
              type="button"
              class="close"
              @click.prevent="CHANGE_CONTECT_US_POP_UP(false)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="fieldFloating">
          <input
            type="text"
            placeholder=" "
            v-model="name"
            class="formControl"
          />
          <label class="fieldFloating__label required-field">Name</label>
          <span v-if="nameErrorMessage" class="error" style="color: red">{{ nameErrorMessage }}</span>
        </div>
        <div class="fieldFloating">
          <input
            type="text"
            placeholder=" "
            v-model="email"
            class="formControl"
          />
          <label class="fieldFloating__label required-field">Email</label>
          <span
            v-if="$v.email.$error"
            class="error"
            style="color: red"
            >Email must be required and valid</span
          >
        </div>
        <div class="fieldFloating">
          <!-- <CInput
            v-model="phoneNumber"
            type="text"
            v-mask="'(###) ###-####'"
            placeholder="Enter Mobile number"
            class="formControl"
          /> -->
          <!-- <span v-if="$v.phoneNumber.$error" class="text-danger"
        >Phone format should be : (000) 000-0000</span
      > -->
          <input
            type="tel"
            placeholder=" "
            v-mask="'(###) ###-####'"
            v-model="phoneNumber"
            class="formControl"
          />
          <!-- <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"> -->
          <label class="fieldFloating__label required-field"
            >Phone number</label
          >
          <span v-if="$v.phoneNumber.$error" class="error" style="color: red"
        >Phone Number is required</span>
        </div>
        <div :class="{ 'fieldFloating': true, 'selected_data': subject !== '' }">
          <label class="fieldFloating__label required-field subject_field">Subject</label>

          <select class="formControl" v-model="subject">
            <option value=""></option>
            <option value="Contact sales">Contact sales</option>
            <option value="Request a tour">Request a tour</option>
            <option value="List a property">List a property</option>
            <option value="Support requests">Support requests</option>
            <option value="Landlord">Landlord</option>
            <option value="Broker">Broker</option>
            <option value="Other inquiries">Other inquiries</option>
          </select>

          <span
            v-if="$v.subject.$error"
            class="error"
            style="color: red"
            >Subject is required</span
          >
        </div>
        <div :class="{ 'fieldFloating': true, 'selected_data': description !== '' } " id="descript_main">

          <textarea v-model="description" maxlength="5000"  class="formControl descript_cus">
          </textarea>
          <label class="fieldFloating__label required-field description-label">Description</label>

          <span
            v-if="$v.description.$error"
            class="error"
            style="color: red"
            >Description is required</span
          >
        </div>

        <button
          class="buttonload btn btn--blue w100"
          style="pointer-events: none"
          v-if="isSubmittingReg"
        >
          Submitting ...
        </button>
        <button
          class="btn btn--blue w100"
          title="Submit"
          v-if="!isSubmittingReg"
          @click.once="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, minLength,maxLength } from "vuelidate/lib/validators";
import { storeContactus } from "@/api/contectus";
import { mapGetters, mapState, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "ContectUsPopUp",
  directives: { mask },
  data: () => ({
    // show: false,
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    description: "",
    selectDescription:false,
    isSubmittingReg: false,
  }),
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(30)
    },
    email: {
      required,
      email,
    },
    subject: {
      required,
    },
    description: {
      required,
    },
    phoneNumber:{
      required
    }
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState("popup", { show: (state) => state.contectUsPopUp }),
    nameErrorMessage() {
    if (!this.$v.name.$dirty) return ''; // If field is untouched, don't show any error
    if (!this.$v.name.required) return 'Name is required';
    if (!this.$v.name.minLength) return 'Name must be at least ' + this.$v.name.$params.minLength.min + ' characters long';
    if (!this.$v.name.maxLength) return 'Name must not exceed ' + this.$v.name.$params.maxLength.max + ' characters';
    return ''; // No error if all conditions are met
  }
  },
  created() {
    // this.clearDetails();
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  mounted() {
    this.name = this.user.user ? this.user.user.name : "";
    this.email = this.user.user ? this.user.user.email : "";
    this.phoneNumber = this.user.user ? this.user.user.mobile : "";
  },
  watch: {
    user: {
      handler(val) {
        this.clearDetails();
        this.name = this.user.user ? this.user.user.name : "";
        this.email = this.user.user ? this.user.user.email : "";
        this.phoneNumber = this.user.user ? this.user.user.mobile : "";
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),
    documentClick(e) {
      let outside = true;
      var path = e.path || (e.composedPath && e.composedPath());
      if (path) {
        for (var i = 0; i < path.length - 1; ++i) {
          if (
            path[i].className != undefined &&
            typeof path[i].className.includes == "function"
          ) {
            if (path[i].className.includes("contectUs")) {
              outside = false;
              break;
            }
          }
        }
      }
      if (outside) {
        this.CHANGE_CONTECT_US_POP_UP(false);
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      
      storeContactus({
        email: this.email,
        phone_number: this.phoneNumber,
        subject: this.subject,
        description: this.description,
        name: this.name,
      })
        .then((res) => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "Contact request send successfully. Someone from WeWork would be contacting you via email or phone",
          });
          this.subject = "";
          this.description = "";
          // this.clearDetails();
          this.CHANGE_CONTECT_US_POP_UP(false);
        })
        .catch((error) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: error.response.data.data.message,
          });
          this.subject = "";
          this.description = "";
          // this.clearDetails();
        });
    },
    clearDetails() {
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
      this.subject = "";
      this.description = "";
    },
  },
};
</script>
<style>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px);
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
.descript_cus{
  resize: none;
}


#descript_main .fieldFloating .formControl:focus ~ label, #descript_main .fieldFloating .formControl:not(:placeholder-shown) ~ label {
	color: #268CAB;
	top: 0px;
}
#descript_main .fieldFloating__label {
    color: #268CAB;
    top: 27px;
   
  }
  
  #descript_main.fieldFloating.selected_data label.fieldFloating__label{
  top: 7px !important;
  color: #28485E !important;
}
#descript_main.fieldFloating.selected_data:focus.fieldFloating__label{
  top: 7px !important;
  color: #28485E !important;
}

#descript_main {
  position: relative;
}

#descript_main:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 1px;
    width: calc(100% - 14px);
    height: 24px;
    border-radius: 4px;
    background-color: #ffffff;
}
#descript_main textarea.form-control {
  padding-top: 32px; /* height of pseudo element */
  min-height: 80px; /* not relevant */
}
.modal__card .contact_search_cus .fieldFloating .formControl:focus ~ label, .fieldFloating .formControl:not(:placeholder-shown) ~ label {
	color: #28485E;
	top: 15px;
}
.fieldFloating .formControl {
        height: 70px !important;
        padding: 20px 31px 5px 20px !important;
    }
    .autocomplete .formControl {
    height: 52px !important;
    padding: 20px 31px 5px 20px !important;
}
    @media (max-height: 1500px) {
    .fieldFloating__label {
        top: 15px;
    }
}
   
</style>