<template>
    <footer class="footer">
      <section class="footer-top" id="footer">
        <div class="container">
          <div class="nav-menu">
            <ul>
              <li><a href="#" target="_blank">Enterprise</a></li>
              <li><a href="#" target="_blank">Book a Tour</a></li>
              <li><a href="#" target="_blank">About Us</a></li>
              <li><a href="#" target="_blank">Blog</a></li>
              <li><a href="#" target="_blank">Contact Us</a></li>
            </ul>
          </div>
          <div class="footer-logo">
            <img
              src="https://extraslice.com/wp-content/uploads/2020/02/white_logo-01-01-300x80.png"
              alt="Logo"
            />
          </div>
          <div class="footer-address">
            <p>Address: 3600 136th Pl SE # 300, Bellevue, WA 98006</p>
            <p>Phone: <a href="tel:+1 4252439447">+1 (425) 243-9447</a></p>
            <p>Email: <a href="#">contact@extraslice.com</a></p>
          </div>
          <div class="social-icons">
            <a href="https://www.facebook.com/extraslice/" target="_blank">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://twitter.com/extrasliceinc" target="_blank">
              <i class="fa-brands fa-square-x-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/extraslice" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </section>
      <section class="footer-bottom">
        <div class="container footer-bottom-section">
          <div class="copyright">
            <p>Copyright © 2024 wework</p>
          </div>
          <div class="privacy-policy">
            <a href="https://extraslice.com/privacy-policy/" target="_blank">Privacy Policy</a>
          </div>
        </div>
      </section>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer'
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #222;
    color: #fff;
    padding: 20px 0 0 0;
    font-family: Arial, sans-serif;
  }
  
  .footer-top {
    padding: 40px 0;
    background-color: #284858;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 6rem;
  }
  
  .nav-menu li {
    margin: 0;
    font-size: larger;
  }
  
  .nav-menu a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .nav-menu a:hover {
    color: #C1C1C1;
  }
  
  .footer-logo img {
    max-width: 100%;
    height: auto;
    margin: 20px 0 0;
  }
  
  .footer-address p {
    margin: 5px 0;
  }

  .footer-address p a{
color: #ffffff
  }
  
  .social-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s;
  }
  
 
  
  .footer-bottom {
    padding: 10px 0;
    background-color: #284858;
  }

  .footer-bottom-section {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
  }
  
  .copyright,
  .privacy-policy {
    text-align: center;
    margin: 0;
  }
  
  .privacy-policy a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  

  </style>
  